import { useEffect, useState } from "react"

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => {
    setWindowWidth(window.innerWidth)

    return () => {}
  }, [])

  return windowWidth
}

export default useWindowWidth
